/* eslint-disable sort-imports */
/* eslint-disable  import/order */
/* eslint sort-imports: ["error"] */
/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue'
// import VueCompositionAPI from '@vue/composition-api'
import VueCompositionAPI, { provide } from '@vue/composition-api'

import { i18n } from '@/plugins/i18n'
import '@/@fake-db/db'
import './plugins/acl'
import '@/styles/styles.scss'
import App from './App.vue'

import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import moment from 'moment'

import VueApollo from 'vue-apollo'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { DefaultApolloClient } from '@vue/apollo-composable'

import VueToastr from 'vue-toastr'

Vue.use(VueCompositionAPI)

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken')
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

if (process.env.NODE_ENV !== 'production') console.log('Environment:', process.env)

const apiUrl = process.env.API_URL.replace(/\/$/, '')
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: `${apiUrl}/graphql`,
  credentials: 'same-origin',
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

Vue.use(VueToastr, {
  defaultProgressBar: false,
  defaultPosition: 'toast-bottom-right',
})

Vue.use(VueApollo)

Vue.prototype.moment = moment
Vue.config.productionTip = false

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

// const apolloProvider = provide(DefaultApolloClient, apolloClient)

new Vue({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  router,
  store,
  i18n,
  vuetify,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
